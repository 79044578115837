import { GEN_AI_IMAGE } from "../Actions/Types";

// Defining Initial State
const initialState = {
  genAiImage: [],
};

// Defining Reducer Function
export const genAiImageState = (state = initialState, action) => {
  // Destructuring Action
  const { type, payload } = action;

  switch (type) {
    // Add  Case
    case GEN_AI_IMAGE: {
      return {
        ...state,
        genAiImage: payload,
      };
    }
    // Default Case
    default: {
      return state;
    }
  }
};
