import "./Masonry.css";
import MasonryCard from "./MasonryCard";

const MasonryImageList = ({
  homePrompts,
  homeDownloadLinks,
  homeImageLinks,
  setHomeCardDetails,
  collectionDownloadLinks,
  collectionImageLinks,
  collectionPrompts,
  setCollectionCardDetails,
}) => {
  if (
    (!homePrompts ||
      !homeDownloadLinks ||
      !homeImageLinks ||
      homeImageLinks.length === 0) &&
    (!collectionPrompts ||
      !collectionDownloadLinks ||
      !collectionImageLinks ||
      collectionImageLinks.length === 0)
  ) {
    return null;
  }

  const showImages = [];

  const addImagesToList = (prompts, downloadLinks, imageLinks, source) => {
    prompts.forEach((prompt, index) => {
      const link = downloadLinks[index] || "";
      const image = imageLinks[index];

      if (source === "home") {
        showImages.push(
          <MasonryCard
            key={index}
            homePrompts={prompt}
            homeDownloadLinks={link}
            homeImageLinks={image}
            setHomeCardDetails={setHomeCardDetails}
          />
        );
      } else if (source === "collection") {
        showImages.push(
          <MasonryCard
            key={index}
            collectionPrompts={prompt}
            collectionDownloadLinks={link}
            collectionImageLinks={image}
            setCollectionCardDetails={setCollectionCardDetails}
          />
        );
      }
    });
  };

  if (homeImageLinks && homeImageLinks.length > 0) {
    addImagesToList(homePrompts, homeDownloadLinks, homeImageLinks, "home");
  }

  if (collectionImageLinks && collectionImageLinks.length > 0) {
    addImagesToList(
      collectionPrompts,
      collectionDownloadLinks,
      collectionImageLinks,
      "collection"
    );
  }

  return <div className="image-list">{showImages}</div>;
};

export default MasonryImageList;
