import React, { useState, useEffect } from "react";
import { isMobile } from "../../Utils/utils";
import stars from "../../Assets/stars.svg";
import "./styles.css";

function LoadingModal() {
  const loadingTexts = [
    "Analyzing Prompt",
    "Creating Generation Job",
    "Optimizing Prompt",
    "Running Image Reference",
    "Analyzing Generative Image",
    "Rendering Image",
  ];

  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [renderingText, setRenderingText] = useState(loadingTexts[0]);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeOut(true);
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) =>
          prevIndex === loadingTexts.length - 1 ? 0 : prevIndex + 1
        );
        setFadeOut(false);
      }, 400);
    }, 3000);

    return () => clearInterval(interval);
  }, [loadingTexts.length]);

  useEffect(() => {
    setRenderingText(loadingTexts[currentTextIndex]);
  }, [currentTextIndex, loadingTexts]);

  return (
    <div
      id="loadingModal"
      className="loadingModal flex-d-col flex-center yellow-bg-color"
    >
      <div className="magic-animation padded-top14 padding-bottom14 d-flex align-items-center">
        <div className="icon magic-animation__icon textCenter">
          <img
            src={stars}
            alt=""
            className={`${isMobile ? "width-50" : "width-100"}`}
          />
        </div>
      </div>
      <p
        className={`HyundaiSansHead-Medium color-blue textCenter ${
          isMobile ? "font-size24" : "font-size35"
        }`}
      >
        Discover unknown horizons with Hyundai CRETA.
      </p>
      <p
        className={`margin-top12 font-size16 transition-text ${
          fadeOut ? "fade-out" : ""
        }`}
      >
        {renderingText}
      </p>
    </div>
  );
}

export default LoadingModal;
