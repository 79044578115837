import axios from "axios";
import { BASE_API } from "../GlobalVariable";

// #region deviceInfoService
export const deviceInfoService = async (data) => {
  try {
    // Url
    const url = `${BASE_API}/user/send-device-info`;

    const body = {
      deviceInfo: [
        {
          mobile: data.mobile,
          browser: data.browser,
          os: data.os,
        },
      ],
    };

    // Config
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer token`,
      },
    };

    const result = await axios.post(url, body, config);

    return result?.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
// #endregion
