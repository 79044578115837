import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Layout/Navbar";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import { isMobile } from "../../Utils/utils";
import facebook from "../../Assets/facebook.svg";
import insta from "../../Assets/insta.svg";
import X from "../../Assets/x.svg";
import yt from "../../Assets/youtube.svg";
import H_hyundai from "../../Assets/HyundaiH.svg";

function Privacy() {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos <= 100);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const handleMyCollection = async () => {
    navigate("/my-collection");
  };

  return (
    <div className="container">
      <Navbar handleCollection={handleMyCollection} visible={visible} />
      <div className="termsContainer">
        <h1 className="terms-heading">Privacy Policy</h1>
        <h4>1. Introduction:</h4>
        <p>
          Welcome to Hyundai Motor India Limited (“Hyundai”, "we," "our," or
          "us") – the creators of{" "}
          <a href="https://UltimateCreta.ai" className="terms-links">
            <span>https://UltimateCreta.ai</span>
          </a>
          . This Privacy Policy is designed to inform you ("User" or "you")
          about how we collect, use, disclose, and protect your personal
          information in connection with your use of our website and any related
          services.
        </p>
        <h4>2. Information We Collect:</h4>
        <h5>a. Personal Information:</h5>
        <p>
          When you register on our site, subscribe to our newsletter, or fill
          out a form, we may collect personal information such as your name,
          email address, mailing address, and phone number.
        </p>
        <h5>b. Usage Information:</h5>
        <p>
          We may collect information about how you interact with our website,
          including pages visited, time spent on each page, and the referring
          page.
        </p>
        <h5>c. Technical Information: </h5>
        <p>
          We may automatically collect technical information about your device
          and internet connection, such as your IP address, browser type, and
          operating system.
        </p>
        <h5>d. Payment Information:</h5>
        <p>
          If you make a purchase on our site, we will collect payment
          information, including credit card details. Please note that we do not
          store payment information on our servers.
        </p>
        <h5>e. Internet or other electronic network usage data:</h5>
        <p>
          We may automatically collect Internet or other electronic network
          usage data including, but not limited to, browsing history,
          clickstream data, search history, and information regarding your
          interactions with an internet website, application, or advertisement,
          including access logs and other usage data related to your use of the
          Site. We collect this information through the use of cookies and other
          tracking technologies.
        </p>
        <h4>3. How We Use Your Information:</h4>
        <br />
        <h5>a. Personalization:</h5>
        <p>
          We use the information collected to personalize your experience on our
          site, providing you with relevant content and features.
        </p>
        <h5>b. Communication:</h5>
        <p>
          We may use your email address to send you information and updates
          related to your account, as well as promotional content. We may also
          use your information to provide, maintain and improve our products,
          services, and Site; to authenticate users; to perform technical
          operations; to communicate with you about our services, to respond to
          your requests; to provide technical support; and for other customer
          service and support purposes.
        </p>
        <h5>c. Improvement of Services:</h5>
        <p>
          We analyze the data collected to improve our website, services, user
          experience and to better understand how users access and use our
          products, services, and Site; for research and analytical purposes,
          such as to evaluate and improve our products, services, Site, and
          business operations; to develop new features, products, or services;
          and to otherwise improve our Site and user experiences.
        </p>

        <h4>4. Data Security:</h4>
        <br />
        <h5>a. Protection Measures: </h5>
        <p>
          We implement reasonable security measures to protect your personal
          information from unauthorized access, disclosure, alteration, and
          destruction.
        </p>
        <h5>b. SSL Encryption: </h5>
        <p>
          Our website uses Secure Socket Layer (SSL) encryption to ensure the
          secure transmission of sensitive information.
        </p>

        <h4>5. Sharing Your Information:</h4>
        <br />
        <h5>a. Affiliates and Subsidiaries:</h5>
        <p>
          We may share your personal information with Hyundai subsidiaries and
          affiliates located in your country or region for operational,
          analytic, and compliance purposes.
        </p>
        <h5>b. Service providers and suppliers: </h5>
        <p>
          We may disclose personal information to our vendors, service
          providers, suppliers, contractors, or agents who use such data in
          order to perform functions on our behalf. These entities are
          contractually required to use the personal information we disclose
          exclusively to provide their respective services, and not for their
          own independent purposes.
        </p>
        <h5>c. Protecting rights and interests: </h5>
        <p>
          We may disclose your information to protect or defend the safety,
          rights, property, or security of Hyundai, third parties, or the
          government authorities or the general public, including to protect the
          Services, to detect, prevent, or otherwise address fraud, security,
          technical issues, or other activity which we, in our sole discretion,
          consider to be, or to pose a risk of being, illegal, unethical, or
          legally actionable activity. We may also use personal information as
          evidence in litigation in which we are involved, and as necessary to
          enforce this Privacy Policy, our Terms of Use, and other applicable
          agreements with you.
        </p>
        <h5>d. Legal compliance and lawful requests:</h5>
        <p>
          We may disclose your information to comply with applicable legal or
          regulatory obligations, including as part of a judicial proceeding, in
          response to a subpoena, warrant, court order, or other legal process,
          or to cooperate with investigations or lawful requests, whether formal
          or informal, from law enforcement or government entities.
        </p>
        <h5>e. De-identified and Aggregated Data:</h5>
        <p>
          We may share aggregate or de-identified information with third parties
          for research, analytics, and other purposes, provided such information
          does not reasonably identify you.
        </p>
        <br />
        <p>
          We do not disclose personal information through the Site to third
          parties in exchange for direct monetary compensation from such third
          parties. We may disclose or make available personal information
          collected through the Site to third parties to receive certain
          services or benefits from them—for example, when we allow third party
          tags to collect information such as browsing history on our Site to
          improve and measure our ad campaigns.
        </p>
        <h4>6. Children's Privacy</h4>
        <p>
          No parts of our products or services are directed to or designed to
          attract anyone under the age of eighteen (18). We do not knowingly
          collect or maintain personal information from any person under the age
          of eighteen. If you are under the age of eighteen, please do not
          provide us with your personal information. If you are a parent of
          someone under the age of eighteen, please do not provide us with their
          personal information.
        </p>
        <h4>7. Your Choices: </h4>
        <br />
        <h5>a. Opt-Out: </h5>
        <p>
          You may opt out of receiving promotional emails by following the
          instructions in those emails.
        </p>
        <h5>b. Access and Correction:</h5>
        <p>
          You have the right to access, correct, or delete your personal
          information. Contact us for assistance.
        </p>
        <h4>8. Changes to this Privacy Policy: </h4>
        <br />
        <h5>a. Updates: </h5>
        <p>
          We reserve the right to update this Privacy Policy at any time. We
          will notify you of any changes by posting the new Privacy Policy on
          this page.
        </p>
        {/* <h4>9. Contact Us: </h4>
        <br /> */}
        {/* <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at [Contact Email].
        </p> */}
      </div>
      <footer className="footer">
        <div
          className={`d-flex justify-content-between padding-bottom24 ${
            isMobile ? "flex-d-col padding-left12" : "flex-d-row"
          }`}
        >
          <div className={`d-flex ${isMobile ? "flex-d-col" : "flex-d-row"}`}>
            <div>
              <img
                src={H_hyundai}
                alt=""
                className={`width-1 ${
                  isMobile
                    ? "margin-bottom16"
                    : "margin-left24 padding-left-right16"
                }`}
              />
            </div>
            <div className="margin-bottom16">
              <a href="/privacy-policy" className="links">
                <span>Privacy Policy</span>
              </a>
              <span className="links">|</span>
              <a href="/terms-of-use" className="links">
                <span>Terms of Use</span>
              </a>
              <span className="links">|</span>
              <a href="/cookie-policy" className="links">
                <span>Cookie Policy</span>
              </a>
            </div>
          </div>
          <div>
            <a
              href="https://www.facebook.com/HyundaiIndia"
              target="_blank"
              className={`${isMobile ? "padding-right8" : "socials"}`}
              rel="noreferrer"
            >
              <img src={facebook} alt="" className="fb" />
            </a>
            <a
              href="https://twitter.com/HyundaiIndia"
              target="_blank"
              className="socials"
              rel="noreferrer"
            >
              <img src={X} alt="" className="x" />
            </a>
            <a
              href="https://www.instagram.com/p/C1jZcbQtfzS/"
              target="_blank"
              className="socials"
              rel="noreferrer"
            >
              <img src={insta} alt="" />
            </a>
            <a
              href="https://www.youtube.com/hyundaiindia"
              target="_blank"
              className="socials"
              rel="noreferrer"
            >
              <img src={yt} alt="" />
            </a>
          </div>
        </div>

        <div className="footer-desc">
          Product information, such as design, properties, and specifications,
          is for reference only and is subject to change without notice. The
          availability of specific product options may vary between countries
          and specific models.
          <br />
          <br />
          Copyright 2024 Hyundai Motor India. All Rights Reserved
        </div>
      </footer>
    </div>
  );
}

export default Privacy;
