import { deviceInfoService } from "../../Services/deviceInfoService";
import { GET_USER_ID } from "./Types";

//#region deviceInfoAction
export const deviceInfoAction = (data) => async (dispatch) => {
  try {
    // Calling Service
    const result = await deviceInfoService(data);

    // Success Alert
    // dispatch(setAlert("", "success", 3000));

    // Dispatching To Reducer
    dispatch({
      type: GET_USER_ID,
      payload: result,
    });

    return result;
  } catch (err) {
    // Errors
    let errors = err.response.data.errors;

    if (errors !== undefined) {
      for (let x of errors) {
        // Errors Alert
        dispatch(x.msg, "error", 3000);
      }
    } else {
      return Promise.reject(err);
    }
  }
};
// #endregion
