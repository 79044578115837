import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Layout/Navbar";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import { isMobile } from "../../Utils/utils";
import facebook from "../../Assets/facebook.svg";
import insta from "../../Assets/insta.svg";
import X from "../../Assets/x.svg";
import yt from "../../Assets/youtube.svg";
import H_hyundai from "../../Assets/HyundaiH.svg";

function Terms() {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos <= 100);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const handleMyCollection = async () => {
    navigate("/my-collection");
  };

  return (
    <div className="container">
      <Navbar handleCollection={handleMyCollection} visible={visible} />
      <div className="termsContainer">
        <h1 className="terms-heading">Terms of Use</h1>
        <h4>Acceptance of Terms</h4>
        <p>
          Please read the following terms carefully. By using Error! Hyperlink
          reference not valid.
          <a href="https://UltimateCreta.ai" className="terms-links">
            <span>https://UltimateCreta.ai</span>
          </a>
          (the <span className="bold">"Website”</span>) you understand and agree
          that you have read the following terms, as well as the privacy policy
          contained in this Site (the{" "}
          <span className="bold">“Privacy Policy”</span>) and will be legally
          bound by them.
          <br />
          <br />
          <br />
          By accessing and using the Website, you agree to comply with and be
          bound by these Terms and Conditions. If you do not agree with any part
          of these terms, you may not use our services.
          <br />
          <br />
          <br />
          <span className="bold">Website</span> provides AI-generated images
          using advanced artificial intelligence technologies. These images are
          created based on algorithms and data inputs given by the user only.
          <br />
          <br />
          <br />
          The rights in the designs, pictures, logos, photographs and content of
          this Site are owned by or licensed to Hyundai Motor India Limited (
          <span className="bold">“HMIL”</span>). These rights are protected by
          copyright, trademarks and other intellectual property rights. You
          shall not copy, reproduce, modify, distribute, republish, display,
          post or transmit any part of this Site without the written permission
          of HMIL. You may view or print individual pages only for your personal
          use.
          <br />
          <br />
          <br />
          Upon approval of the Terms of Use by using our Site, all image rights
          of the prompted images are assigned to HMIL. The images being created
          through our Site may be used and distributed without any restrictions
          in terms of time, space, subject matter and content. The image may be
          published both in unchanged and in edited form without mentioning the
          name and the source. By using our services, you grant HMIL a
          worldwide, non-exclusive, royalty-free license to use, reproduce,
          modify, adapt, publish, translate, distribute, and display any content
          you upload, submit, or make available on the website.
        </p>
        <h4>Prohibited Conduct</h4>
        <p>You agree not to:</p>
        <br />
        <p>a. Violate any applicable laws or regulations. </p>
        <p>b. Infringe on the intellectual property rights of others. </p>
        <p>c. Use the website for any illegal or unauthorized purpose. </p>
        <p>
          d. Attempt to access, tamper with, or use non-public areas of the
          website.
        </p>
        <h4>Liability</h4>
        <p>
          HMIL shall not make any representation or warranty in respect of
          accuracy, completeness, conditions, applicability, commercial
          liquidity or disposal, use of the content of the Site. The content of
          this Site should only be used for reference purposes and you should
          not rely on it to make (or refrain from making) any decision or take
          (or refrain from taking) any action. In particular, vehicle
          specifications shown on this Site are based upon the latest available
          information and the information may change from time to time. The
          colors and finishes being reproduced may also vary from the actual
          color and paint finishes on the vehicles themselves. We strongly
          recommend you visit your nearest HMIL dealer to know more about the
          desired vehicle and to inspect the vehicles personally. For the
          avoidance of doubt, HMIL disclaims all warranties, express or implied,
          including implied warranties of merchantability and fitness for a
          particular purpose.
        </p>
        <p>
          This Site is for entertainment and your personal use only and is not
          to be used for any commercial purpose. HMIL shall not be liable to you
          for any indirect, incidental, special or consequential loss or damage,
          loss of profits or anticipated profits, economic loss, corruption or
          destruction of data irrespective of whether (i) the loss or damage is
          caused by or relates to breach of contract, statute, tort (including
          negligence) or otherwise; or (ii) HMIL was previously notified of the
          possibility of the loss or damage.
        </p>
        <p>
          HMIL is not liable for any damages or losses resulting from your
          inability to use this Site. HMIL shall not promise that this Site will
          be uninterrupted or entirely error free. Because of the nature of the
          Internet, this Site is provided on an 'as available' basis. HMIL will
          not be responsible to you if you are unable to access this Site for
          any reason including HMIL's decision to cease provision of all or part
          of this Site.
        </p>
        <p>
          You shall compensate, indemnify and hold harmless HMIL, its
          affiliates, and its employees (or any successor thereof) against any
          costs, penalties, duties, orders, judgment for monetary damages,
          settlements, expenses or any other losses, including, without
          limitation, reasonable attorney's fees, arising out of any complaints,
          claims, actions, investigations, or other proceedings in connection
          with any (i) intentional misconduct or negligent act or omission
          relating to this Terms of Use, (ii) breach of this Terms of Use or
          (iii) violation of any applicable laws or regulation by you.
        </p>
        <h4>Content and advertisements</h4>
        <p>
          This Site also provides links to and content from other Internet sites
          and resources. You acknowledge and agree that HMIL is not responsible
          for the availability of these external sites or resources, has no
          control over these sites and resources and shall not endorse (and is
          not responsible for) any content, advertising products, services or
          other materials on or available from these sites or resources. You
          shall endorse and responsible for all risks arising from the use of an
          external site or resource including clicking on a link to an external
          site provided in this Site.
        </p>
        <h4>Religious Sensitivities and Legal Compliance</h4>
        <p>
          While HMIL endeavours to ensure that AI-generated images do not hurt
          religious sentiments or violate any legal requirements, users are
          responsible for confirming their inputs, choice, or contents and the
          appropriateness of the images for their specific use cases. HMIL
          disclaims any liability for potential harm caused by the use of
          AI-generated images.
        </p>
        <h4>Changes to the terms</h4>
        <p>
          HMIL may change this Site, including the Private Policy, or these
          terms at any time. If you use, or continue using, this Site after HMIL
          has changed the terms you agree that you will be bound by the new
          terms.
        </p>
        <h4>Governing Law and Jurisdiction</h4>
        <p>
          The terms set forth herein, as well as the Privacy Policy, shall be
          governed by and construed, in accordance with laws of India without
          reference to its conflicts of law principles and as if fully performed
          therein, and will bind the successions and assigns of each Party. All
          disputes arising from or relating to the terms set forth herein or the
          Privacy Policy shall be brought in the courts of New Delhi, India, and
          you hereby consent to the exclusive jurisdiction of such court.
        </p>
        <h4>Disclaimer of Warranty</h4>
        <p>
          User understand and acknowledges that this program is designed only
          for entertainment purposes only and HMIL makes no warranties, express
          or implied, regarding the accuracy, reliability, or suitability of the
          AI-generated images for any particular purpose.
        </p>
        <h4>Miscellaneous</h4>
        <p>
          If any of the terms set forth herein or in the Privacy Policy shall be
          invalid, illegal, or unenforceable in any respect under any applicable
          law, such term shall be ineffective only to the extent of such
          invalidity, illegality or unenforceability and the validity, legality,
          and enforceability of the remaining terms shall not in any way be
          affected or impaired. Except provided otherwise herein or in the
          Privacy Policy, the terms set forth herein and the Privacy Policy
          shall constitute the entire agreement regarding the Site and its use,
          and supersede all prior or contemporaneous oral or written agreements,
          understandings or arrangements between you and HMIL in relation with
          the Site and its use.
        </p>
        {/* <h4> OpenSource Distribution</h4>
        <p>
          This website provides necessary notice and source code information
          regarding the OpenSource software included in our product.
        </p>
        <a
          href="https://www.hyundai.com/worldwide/en/opensource"
          className="terms-links"
          target="__blank"
        >
          <span>More About</span>
        </a> */}
      </div>
      <footer className="footer">
        <div
          className={`d-flex justify-content-between padding-bottom24 ${
            isMobile ? "flex-d-col padding-left12" : "flex-d-row"
          }`}
        >
          <div className={`d-flex ${isMobile ? "flex-d-col" : "flex-d-row"}`}>
            <div>
              <img
                src={H_hyundai}
                alt=""
                className={`width-1 ${
                  isMobile
                    ? "margin-bottom16"
                    : "margin-left24 padding-left-right16"
                }`}
              />
            </div>
            <div className="margin-bottom16">
              <a href="/privacy-policy" className="links">
                <span>Privacy Policy</span>
              </a>
              <span className="links">|</span>
              <a href="/terms-of-use" className="links">
                <span>Terms of Use</span>
              </a>
              <span className="links">|</span>
              <a href="/cookie-policy" className="links">
                <span>Cookie Policy</span>
              </a>
            </div>
          </div>
          <div>
            <a
              href="https://www.facebook.com/HyundaiIndia"
              target="_blank"
              className={`${isMobile ? "padding-right8" : "socials"}`}
              rel="noreferrer"
            >
              <img src={facebook} alt="" className="fb" />
            </a>
            <a
              href="https://twitter.com/HyundaiIndia"
              target="_blank"
              className="socials"
              rel="noreferrer"
            >
              <img src={X} alt="" className="x" />
            </a>
            <a
              href="https://www.instagram.com/p/C1jZcbQtfzS/"
              target="_blank"
              className="socials"
              rel="noreferrer"
            >
              <img src={insta} alt="" />
            </a>
            <a
              href="https://www.youtube.com/hyundaiindia"
              target="_blank"
              className="socials"
              rel="noreferrer"
            >
              <img src={yt} alt="" />
            </a>
          </div>
        </div>

        <div className="footer-desc">
          Product information, such as design, properties, and specifications,
          is for reference only and is subject to change without notice. The
          availability of specific product options may vary between countries
          and specific models.
          <br />
          <br />
          Copyright 2024 Hyundai Motor India. All Rights Reserved
        </div>
      </footer>
    </div>
  );
}

export default Terms;
