import React from "react";
import add from "../../Assets/add.svg";
import edit from "../../Assets/edit.svg";

function Button({ buttonHeading, isActive, onClick }) {
  const icon = isActive ? edit : add;

  return (
    <div className="margin-top18">
      <button
        className={`common-button ${isActive ? "active" : ""}`}
        onClick={onClick}
      >
        <img src={icon} alt="" className="margin-right12" />
        {buttonHeading}
      </button>
    </div>
  );
}

export default Button;
