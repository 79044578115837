import { GET_GRID_IMG } from "../Actions/Types";

// Defining Initial State
const initialState = {
  gridImgList: [],
};

// Defining Reducer Function
export const getGridState = (state = initialState, action) => {
  // Destructuring Action
  const { type, payload } = action;

  switch (type) {
    // Add GET_GRID_IMG Case
    case GET_GRID_IMG: {
      return {
        ...state,
        gridImgList: payload,
      };
    }
    // Default Case
    default: {
      return state;
    }
  }
};
