import { getGridImageService } from "../../Services/getGridImageService";
import { GET_GRID_IMG } from "./Types";

//#region getGridAction
export const getGridAction = () => async (dispatch) => {
  try {
    // Calling Service
    const result = await getGridImageService();

    // Success Alert
    // dispatch(setAlert("", "success", 3000));

    // Dispatching To Reducer
    dispatch({
      type: GET_GRID_IMG,
      payload: result,
    });

    return result;
  } catch (err) {
    // Errors
    let errors = err.response.data.errors;

    if (errors !== undefined) {
      for (let x of errors) {
        // Errors Alert
        dispatch((x.msg, "error", 3000));
      }
    } else {
      return Promise.reject(err);
    }
  }
};
// #endregion
