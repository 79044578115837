import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import H_hyundai from "../../Assets/HyundaiH.svg";
import facebook from "../../Assets/facebook.svg";
import insta from "../../Assets/insta.svg";
import X from "../../Assets/x.svg";
import yt from "../../Assets/youtube.svg";
import Modal1 from "../../Components/Common/Modal1.jsx";
import Modal2 from "../../Components/Common/Modal2.jsx";
import PreviewScreen from "../../Components/Common/PreviewScreen.jsx";
import MasonryImageList from "../../Components/Layout/MasonryList.jsx";
import Navbar from "../../Components/Layout/Navbar";
import { deviceInfoAction } from "../../Redux/Actions/deviceInfoAction.js";
import { getGridAction } from "../../Redux/Actions/getGridAction";
import { myCollectionAction } from "../../Redux/Actions/myCollectionAction";
import { isMobile } from "../../Utils/utils";
import ErrorModal from "../../Components/Common/ErrorModal.jsx";

function HomeView({ deviceInfoAction, getGridAction }) {
  const navigate = useNavigate();
  const [showModal1, setShowModal1] = useState(true);
  const [showModal2, setShowModal2] = useState(false);
  const [response, setResponse] = useState(null);
  const [allPrompts1, setAllPrompts1] = useState();
  const [allPrompts2, setAllPrompts2] = useState();
  const [allPrompts3, setAllPrompts3] = useState();
  const [allPrompts4, setAllPrompts4] = useState();
  const [allPrompts5, setAllPrompts5] = useState();
  const [allPrompts6, setAllPrompts6] = useState();
  const [imageslist1, setImageslist1] = useState([]);
  const [imageslist2, setImageslist2] = useState([]);
  const [imageslist3, setImageslist3] = useState([]);
  const [imageslist4, setImageslist4] = useState([]);
  const [imageslist5, setImageslist5] = useState([]);
  const [imageslist6, setImageslist6] = useState([]);
  const [allDownloadLink1, setAllDownloadLink1] = useState();
  const [allDownloadLink2, setAllDownloadLink2] = useState();
  const [allDownloadLink3, setAllDownloadLink3] = useState();
  const [allDownloadLink4, setAllDownloadLink4] = useState();
  const [allDownloadLink5, setAllDownloadLink5] = useState();
  const [allDownloadLink6, setAllDownloadLink6] = useState();
  const [count1, setCount1] = useState(9);
  const [count2, setCount2] = useState(7);
  const [count3, setCount3] = useState(17);
  const [count4, setCount4] = useState(15);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [showMoreButton, setShowMoreButton] = useState(true);
  const [visible, setVisible] = useState(true);
  const [fade, setFade] = useState(true);
  const [homeCardDetails, setHomeCardDetails] = useState([]);
  const userId = localStorage.getItem("userId");

  const getBrowserInfo = (userAgent) => {
    const browsers = {
      Chrome: /Chrome/i.test(userAgent),
      Safari: /Safari/i.test(userAgent),
      Firefox: /Firefox/i.test(userAgent),
      Edge: /Edg/i.test(userAgent),
      IE: /Trident/i.test(userAgent),
    };

    return (
      Object.keys(browsers).find((browser) => browsers[browser]) || "Unknown"
    );
  };

  const getOperatingSystem = (userAgent) => {
    const operatingSystems = {
      Windows: /Windows/i.test(userAgent),
      MacOS: /Mac/i.test(userAgent),
      iOS: /iPhone|iPad|iPod/i.test(userAgent),
      Android: /Android/i.test(userAgent),
      Linux: /Linux/i.test(userAgent),
    };

    return (
      Object.keys(operatingSystems).find((os) => operatingSystems[os]) ||
      "Unknown"
    );
  };

  // useEffect(() => {
  //   const storedUserId = localStorage.getItem("userId");
  //   if (storedUserId) {
  //     // console.log("userId-", storedUserId);
  //   } else {
  //     const getDeviceInfo = async () => {
  //       const userAgent = navigator.userAgent;
  //       const browser = getBrowserInfo(userAgent);
  //       const os = getOperatingSystem(userAgent);

  //       const payload = {
  //         mobile: userAgent,
  //         browser: browser,
  //         os: os,
  //       };

  //       try {
  //         const deviceInfoFromAction = await deviceInfoAction(payload);

  //         localStorage.setItem("userId", deviceInfoFromAction?.userId);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     };

  //     getDeviceInfo();
  //   }
  // }, []);

  useEffect(() => {
    getLatestImages();
  }, [count1]);

  const getLatestImages = () => {
    let fetchData = async () => {
      try {
        const collectionListFromAction = await getGridAction();

        const allImages = collectionListFromAction.result;

        const slicedArrays = [];
        const slicedMobileArrays = [];
        let startIndex = 0;
        let startIndexMobile = 0;
        const sliceSizes = [count1, count2, count1, count2];

        const sliceSizeMobile = [count3, count4];

        for (const size of sliceSizes) {
          const endIndex = startIndex + size;
          const slicedArray = allImages.slice(startIndex, endIndex);
          slicedArrays.push(slicedArray);
          startIndex = endIndex;
        }

        for (const size of sliceSizeMobile) {
          const endIndex = startIndexMobile + size;
          const slicedArraysMobile = allImages.slice(
            startIndexMobile,
            endIndex
          );
          slicedMobileArrays.push(slicedArraysMobile);
          startIndexMobile = endIndex;
        }

        const imageUrls1 = slicedArrays[0].map(
          (image) => image.s3_image_url_watchable
        );
        const imageUrls2 = slicedArrays[1].map(
          (image) => image.s3_image_url_watchable
        );
        const imageUrls3 = slicedArrays[2].map(
          (image) => image.s3_image_url_watchable
        );
        const imageUrls4 = slicedArrays[3].map(
          (image) => image.s3_image_url_watchable
        );
        const imageUrls5 = slicedMobileArrays[0].map(
          (image) => image.s3_image_url_watchable
        );
        const imageUrls6 = slicedMobileArrays[1].map(
          (image) => image.s3_image_url_watchable
        );

        const promptStr1 = slicedArrays[0]
          .filter((image) => image.promptString || image.freeText)
          .map((image) => image.promptString || image.freeText);

        const promptStr2 = slicedArrays[1]
          .filter((image) => image.promptString || image.freeText)
          .map((image) => image.promptString || image.freeText);

        const promptStr3 = slicedArrays[2]
          .filter((image) => image.promptString || image.freeText)
          .map((image) => image.promptString || image.freeText);

        const promptStr4 = slicedArrays[3]
          .filter((image) => image.promptString || image.freeText)
          .map((image) => image.promptString || image.freeText);

        const promptStr5 = slicedMobileArrays[0]
          .filter((image) => image.promptString || image.freeText)
          .map((image) => image.promptString || image.freeText);

        const promptStr6 = slicedMobileArrays[1]
          .filter((image) => image.promptString || image.freeText)
          .map((image) => image.promptString || image.freeText);

        const downloadLink1 = slicedArrays[0].map(
          (image) => image.s3_image_url_downloadable
        );
        const downloadLink2 = slicedArrays[1].map(
          (image) => image.s3_image_url_downloadable
        );
        const downloadLink3 = slicedArrays[2].map(
          (image) => image.s3_image_url_downloadable
        );
        const downloadLink4 = slicedArrays[3].map(
          (image) => image.s3_image_url_downloadable
        );
        const downloadLink5 = slicedMobileArrays[0].map(
          (image) => image.s3_image_url_downloadable
        );
        const downloadLink6 = slicedMobileArrays[1].map(
          (image) => image.s3_image_url_downloadable
        );

        setAllPrompts1(promptStr1);
        setAllPrompts2(promptStr2);
        setAllPrompts3(promptStr3);
        setAllPrompts4(promptStr4);
        setAllPrompts5(promptStr5);
        setAllPrompts6(promptStr6);
        setAllDownloadLink1(downloadLink1);
        setAllDownloadLink2(downloadLink2);
        setAllDownloadLink3(downloadLink3);
        setAllDownloadLink4(downloadLink4);
        setAllDownloadLink5(downloadLink5);
        setAllDownloadLink6(downloadLink6);
        setImageslist1(imageUrls1);
        setImageslist2(imageUrls2);
        setImageslist3(imageUrls3);
        setImageslist4(imageUrls4);
        setImageslist5(imageUrls5);
        setImageslist6(imageUrls6);

        // Below are the code for Mobile Devices
        // const imageUrls5 = imageUrls1.concat(imageUrls3);
        // const imageUrls6 = imageUrls2.concat(imageUrls4);

        // while (imageUrls5.length > 17) {
        //   imageUrls6.unshift(imageUrls5.pop());
        // }

        // while (imageUrls6.length > 15) {
        //   imageUrls5.push(imageUrls6.shift());
        // }

        // const promptStr5 = promptStr1.concat(promptStr3);
        // const promptStr6 = promptStr2.concat(promptStr4);

        // const downloadLink5 = downloadLink1.concat(downloadLink3);
        // const downloadLink6 = downloadLink2.concat(downloadLink4);

        // while (promptStr5.length > 17) {
        //   promptStr6.unshift(promptStr5.pop());
        //   downloadLink6.unshift(downloadLink5.pop());
        // }

        // while (promptStr6.length > 15) {
        //   promptStr5.push(promptStr6.shift());
        //   downloadLink5.push(downloadLink6.shift());
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  };

  const loadMoreImages = () => {
    if (count1 < 51 && count2 < 49) {
      setCount1(count1 + 7);
      setCount2(count2 + 7);
    }
    if (count3 < 38 && count4 < 36) {
      setCount3(count3 + 7);
      setCount4(count4 + 7);
    }

    if (count1 >= 51 || count2 >= 49 || count3 >= 38 || count4 >= 36) {
      setShowMoreButton(false);
    }
  };

  const Modal1Button = () => {
    setShowModal1(false);
    setShowModal2(true);
  };

  const handleMyCollection = async () => {
    navigate("/my-collection");

    try {
    } catch (ex) {
      console.log("Exception at submitting image", ex);
      alert("Something went wrong, please try again");
    }
  };

  const handleModalToggle = () => {
    setShowModal1(true);
    setShowModal2(false);
  };

  useEffect(() => {
    const scrollGrid1 = document.getElementById("scrollGrid1");
    const scrollGrid2 = document.getElementById("scrollGrid2");

    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const contentHeight = document.documentElement.scrollHeight;
      const scrollPosition = window.scrollY || window.pageYOffset;

      const maxTranslation = contentHeight - windowHeight;
      let translation1, translation2;

      // For mobile devices
      if (window.innerWidth <= 576) {
        translation1 = (scrollPosition / maxTranslation) * 467;
        translation2 = (scrollPosition / maxTranslation) * 467;
      }
      // For tablets
      else if (window.innerWidth <= 1024) {
        translation1 = (scrollPosition / maxTranslation) * 504;
        translation2 = (scrollPosition / maxTranslation) * 504;
      }
      // For larger screens
      else {
        translation1 = (scrollPosition / maxTranslation) * 891;
        translation2 = (scrollPosition / maxTranslation) * 891;
      }

      // Limit translations based on screen size
      translation1 = Math.min(
        translation1,
        window.innerWidth <= 576 ? 467 : window.innerWidth <= 1024 ? 504 : 900
      );
      translation2 = Math.min(
        translation2,
        window.innerWidth <= 576 ? 467 : window.innerWidth <= 1024 ? 504 : 900
      );

      // Apply translation to elements
      scrollGrid1.style.transform = `translate3d(0, ${translation1}px, 0)`;
      scrollGrid2.style.transform = `translate3d(0, ${translation2}px, 0)`;
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos <= 100);
      setFade(currentScrollPos <= 50);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <div className="container">
      <Navbar handleCollection={handleMyCollection} visible={visible} />
      <>
        <div className={`headingContainer ${fade ? "show" : "fade"} `}>
          <h1 className={`hyundai-heading ${isMobile ? "mb-10" : "mb-20"}`}>
            The new Hyundai CRETA.
          </h1>
          <h3 className={`subheading  ${isMobile ? "mb-10" : "mb-20"}`}>
            Undisputed. Ultimate.
          </h3>
          <p className="textCenter desc">
            Unleash your imagination with AI and discover a whole new world of
            unexplored destinations with the new Hyundai CRETA. All you have to
            do is tell us where you want to go!
          </p>
        </div>
        <div className="d-flex justify-content-center overflow-hidden home-grid">
          {response?.length === 0 ? (
            <div className="subheading margin-bottom16">
              Create some images to show here
            </div>
          ) : isMobile ? (
            <div className="mobile-masonry d-flex flex-d-row">
              <div className="mobile-row d-flex flex-d-col">
                <div
                  id="scrollGrid1"
                  className={` ${
                    imageslist6.length > 0
                    // ? "fade-slide-up fade-slide-up-enter-done"
                    // : "fade-slide-up"
                  }`}
                >
                  <MasonryImageList
                    homeImageLinks={imageslist6}
                    homeDownloadLinks={allDownloadLink6}
                    homePrompts={allPrompts6}
                    setHomeCardDetails={setHomeCardDetails}
                  />
                </div>
              </div>
              <div className="mobile-row d-flex flex-d-col">
                <div
                  id="scrollGrid1"
                  className={`${
                    imageslist5.length > 0
                    // ? "fade-slide-up fade-slide-up-enter-done"
                    // : "fade-slide-up"
                  }`}
                >
                  <MasonryImageList
                    homeImageLinks={imageslist5}
                    homeDownloadLinks={allDownloadLink5}
                    homePrompts={allPrompts5}
                    setHomeCardDetails={setHomeCardDetails}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                className={` padding8 ${
                  imageslist1.length > 0
                  // ? "fade-slide-up fade-slide-up-enter-done"
                  // : "fade-slide-up"
                }`}
              >
                <MasonryImageList
                  homeImageLinks={imageslist1}
                  homeDownloadLinks={allDownloadLink1}
                  homePrompts={allPrompts1}
                  setHomeCardDetails={setHomeCardDetails}
                />
              </div>
              <div
                id="scrollGrid1"
                className={` padding-top8 ${
                  imageslist2.length > 0
                  // ? "fade-slide-up fade-slide-up-enter-done"
                  // : "fade-slide-up"
                }`}
              >
                <MasonryImageList
                  homeImageLinks={imageslist2}
                  homeDownloadLinks={allDownloadLink2}
                  homePrompts={allPrompts2}
                  setHomeCardDetails={setHomeCardDetails}
                />
              </div>
              <div
                className={` padding8 ${
                  imageslist3.length > 0
                  // ? "fade-slide-up fade-slide-up-enter-done"
                  // : "fade-slide-up"
                }`}
              >
                <MasonryImageList
                  homeImageLinks={imageslist3}
                  homeDownloadLinks={allDownloadLink3}
                  homePrompts={allPrompts3}
                  setHomeCardDetails={setHomeCardDetails}
                />
              </div>
              <div
                id="scrollGrid2"
                className={` padding-top8 ${
                  imageslist4.length > 0
                  // ? "fade-slide-up fade-slide-up-enter-done"
                  // : "fade-slide-up"
                }`}
              >
                <MasonryImageList
                  homeImageLinks={imageslist4}
                  homeDownloadLinks={allDownloadLink4}
                  homePrompts={allPrompts4}
                  setHomeCardDetails={setHomeCardDetails}
                />
              </div>
            </>
          )}
        </div>
        {showMoreButton && (
          <div className={`Button-div ${isMobile ? "show" : "show"}`}>
            <button className="show-more" onClick={loadMoreImages}>
              Show More
            </button>
          </div>
        )}
        <footer className="footer">
          <div
            className={`d-flex justify-content-between padding-bottom24 ${
              isMobile ? "flex-d-col padding-left12" : "flex-d-row"
            }`}
          >
            <div className={`d-flex ${isMobile ? "flex-d-col" : "flex-d-row"}`}>
              <div>
                <img
                  src={H_hyundai}
                  alt=""
                  className={`width-1 ${
                    isMobile
                      ? "margin-bottom16"
                      : "margin-left24 padding-left-right16"
                  }`}
                />
              </div>
              <div className="margin-bottom16">
                <a href="/privacy-policy" className="links">
                  <span>Privacy Policy</span>
                </a>
                <span className="links">|</span>
                <a href="/terms-of-use" className="links">
                  <span>Terms of Use</span>
                </a>
                <span className="links">|</span>
                <a href="/cookie-policy" className="links">
                  <span>Cookie Policy</span>
                </a>
              </div>
            </div>
            <div>
              <a
                href="https://www.facebook.com/HyundaiIndia"
                target="_blank"
                className={`${isMobile ? "padding-right8" : "socials"}`}
                rel="noreferrer"
              >
                <img src={facebook} alt="" className="fb" />
              </a>
              <a
                href="https://twitter.com/HyundaiIndia"
                target="_blank"
                className="socials"
                rel="noreferrer"
              >
                <img src={X} alt="" className="x" />
              </a>
              <a
                href="https://www.instagram.com/p/C1jZcbQtfzS/"
                target="_blank"
                className="socials"
                rel="noreferrer"
              >
                <img src={insta} alt="" />
              </a>
              <a
                href="https://www.youtube.com/hyundaiindia"
                target="_blank"
                className="socials"
                rel="noreferrer"
              >
                <img src={yt} alt="" />
              </a>
            </div>
          </div>

          <div className="footer-desc">
            Product information, such as design, properties, and specifications,
            is for reference only and is subject to change without notice. The
            availability of specific product options may vary between countries
            and specific models.
            <br />
            <br />
            Copyright 2024 Hyundai Motor India. All Rights Reserved
          </div>
        </footer>
        <div className="flex-center">
          {showModal1 && <Modal1 Modal1Button={Modal1Button} />}
          {showModal2 && (
            <Modal2
              handleModalToggle={handleModalToggle}
              getLatestImages={getLatestImages}
            />
          )}
        </div>
      </>

      {homeCardDetails.length !== 0 && (
        <PreviewScreen
          homeCardDetails={homeCardDetails}
          setHomeCardDetails={setHomeCardDetails}
          activeDetails="home"
        />
      )}
    </div>
  );
}

// Defining Proptypes
HomeView.prototype = {
  deviceInfoAction: PropTypes.func.isRequired,
  myCollectionAction: PropTypes.func.isRequired,
  getGridAction: PropTypes.func.isRequired,
};

export default connect(null, {
  deviceInfoAction,
  myCollectionAction,
  getGridAction,
})(HomeView);
