import React from "react";
import closeIcon from "../../Assets/close.svg";
import "./styles.css";

const FullScreenImage = ({ imageUrl, onCloseFullScreen }) => {
  const handleCloseFullScreen = () => {
    onCloseFullScreen();
  };

  return (
    <div className="fullscreen-container">
      <div className="fullscreen-image">
        <img
          src={imageUrl}
          alt="Full Screen"
          className="object-fit-cover width-100 height-100"
        />
        <div className="close-button" onClick={handleCloseFullScreen}>
          <img src={closeIcon} alt="Close" />
        </div>
      </div>
    </div>
  );
};

export default FullScreenImage;
