export const destinations = [
  "Breathtaking Canyon Exploration",
  "Thrilling off-road adventures",
  "Rolling Country Meadows",
  "Picturesque Waterfall Hikes",
  "Pristine Coastal Beaches",
  "Majestic Mountain Peaks",
  "Remote Campsites",
  "Vast Desert Landscapes",
  "Serene Lakefront Retreats",
  "Enchanting Forest Hideaways",
];

export const mood = [
  "Star-Studded Night Sky",
  "Moonlit Silhouette",
  "Burning Sun",
  "Tranquil Midday bliss",
  "Majestic Moonrise",
  "Gentle Morning Glow",
  "Radiant Sunset Sky",
  "Glowing Golden Hour",
  "Luminous Full Moon",
  "Enchanting Dawn Sky",
];

export const weather = [
  "Blissful Sunshine",
  "Calming Rain",
  "Dramatic Thunderstorm",
  "Mysteriuous Fog",
  "Cozy Snowfall",
  "Serene Mist",
  "Vibrant Rainbow",
  "Gentle Spring Showers",
];

export const elements = [
  "Rough Shapes",
  "Candy Pleasures",
  "Shiny Objects",
  "Translucent Flowers",
  "Geometrical Forms",
  "Exotic Fruits",
  "Furry Surfaces",
  "Childish Gadgets",
];
