import { useEffect, useRef, useState } from "react";

const MasonryCard = ({
  homeImageLinks,
  homeDownloadLinks,
  homePrompts,
  setHomeCardDetails,
  setCollectionCardDetails,
  collectionDownloadLinks,
  collectionImageLinks,
  collectionPrompts,
}) => {
  const [spans, setSpans] = useState(0);
  const imageRef = useRef(null);

  const handleOpenFullScreen = () => {
    if (homeImageLinks) {
      setHomeCardDetails({
        downloadLink: homeDownloadLinks,
        prompt: homePrompts,
        image: homeImageLinks,
      });
    } else {
      setCollectionCardDetails({
        downloadLink: collectionDownloadLinks,
        prompt: collectionPrompts,
        image: collectionImageLinks,
      });
    }
  };

  //Sorry—something went wrong. Please try again. Avoid specific brands or animals/humans in your prompt and check for any missing letters.

  useEffect(() => {
    const setSpansFromImage = () => {
      if (imageRef.current && imageRef.current.clientHeight > 0) {
        const height = imageRef.current.clientHeight;
        const spansCount = Math.ceil(height / 10.5);
        setSpans(spansCount);
      }
    };

    if (imageRef.current && imageRef.current.complete) {
      setSpansFromImage();
    } else {
      imageRef.current.addEventListener("load", setSpansFromImage);
    }

    return () => {
      if (imageRef.current) {
        imageRef.current.removeEventListener("load", setSpansFromImage);
      }
    };
  }, [homeImageLinks, collectionImageLinks]);

  return (
    <div
      style={{ gridRowEnd: `span ${spans}` }}
      className="zoom-effect cursorPointer"
      onClick={handleOpenFullScreen}
    >
      <img
        ref={imageRef}
        src={homeImageLinks || collectionImageLinks}
        alt="image88"
      />
    </div>
  );
};

export default MasonryCard;
