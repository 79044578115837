import axios from "axios";
import { BASE_API } from "../GlobalVariable";

export const genAiImageService = async (data) => {
  try {
    const url = `${BASE_API}/user/genrate-ai-image`;

    let body;

    if (data.tab === "Assistive") {
      body = {
        userId: data.userId,
        prompts: [
          {
            Choose_your_Destination: data.Choose_your_Destination,
            Celestial_Mood: data.Celestial_Mood,
            Preferred_Weather: data.Preferred_Weather,
            Surrounding_Elements: data.Surrounding_Elements,
          },
        ],
      };
    } else {
      body = {
        userId: data.userId,
        freeText: data.freeText,
        prompts: [],
      };
    }

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer token`,
      },
    };

    const result = await axios.post(url, body, config);
    console.log("result", result.data.error);

    return result?.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
