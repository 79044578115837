import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import H_hyundai from "../../Assets/HyundaiH.svg";
import facebook from "../../Assets/facebook.svg";
import insta from "../../Assets/insta.svg";
import X from "../../Assets/x.svg";
import yt from "../../Assets/youtube.svg";
import PreviewScreen from "../../Components/Common/PreviewScreen";
import MasonryImageList from "../../Components/Layout/MasonryList";
import Navbar from "../../Components/Layout/Navbar";
import { myCollectionAction } from "../../Redux/Actions/myCollectionAction";
import { isMobile } from "../../Utils/utils";

function MyCollectionView({ myCollectionAction, collectionList }) {
  const [fade, setFade] = useState(true);
  const [visible, setVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [collectionCardDetails, setCollectionCardDetails] = useState([]);
  const userId = localStorage.getItem("userId");

  const collectionImg = async () => {
    await myCollectionAction(userId);
    // console.log("collectionImg", collectionImg.images[0].prompts);
  };

  const collectionPrompts = collectionList?.images?.map(
    (images) => images.prompts
  );
  const collectionImageLinks = collectionList?.images?.map(
    (images) => images.s3_image_url_watchable
  );
  const collectionDownloadLinks = collectionList?.images?.map(
    (images) => images.s3_image_url_downloadable
  );

  const collectionFreeText = collectionList?.images
    ?.filter((image) => !image.prompts)
    .map((image) => image.freeText);

  useEffect(() => {
    collectionImg();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos <= 100);
      setFade(currentScrollPos <= 50);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <div className="container">
      <Navbar visible={visible} />
      <div className={`headingContainer ${fade ? "show" : "fade"} `}>
        <h1 className={`hyundai-heading ${isMobile ? "mb-10" : "mb-20"}`}>
          My Collection
        </h1>
        {(!userId || userId.trim() === "") && (
          <h3 className="subheading">Create some images to show here.</h3>
        )}
      </div>
      <div className="d-flex justify-content-center overflow-hidden collection-grid">
        <div
          className={`width-97 ${
            collectionList?.images?.length > 0
              ? "fade-slide-up fade-slide-up-enter-done"
              : "fade-slide-up"
          }`}
        >
          <MasonryImageList
            collectionDownloadLinks={collectionDownloadLinks}
            collectionImageLinks={collectionImageLinks}
            collectionPrompts={collectionPrompts || collectionFreeText}
            setCollectionCardDetails={setCollectionCardDetails}
          />
        </div>
      </div>
      {collectionCardDetails.length !== 0 && (
        <PreviewScreen
          collectionCardDetails={collectionCardDetails}
          setCollectionCardDetails={setCollectionCardDetails}
          activeDetails="collection"
        />
      )}
      <footer className="footer">
        <div
          className={`d-flex justify-content-between padding-bottom24 ${
            isMobile ? "flex-d-col padding-left12" : "flex-d-row"
          }`}
        >
          <div className={`d-flex ${isMobile ? "flex-d-col" : "flex-d-row"}`}>
            <div>
              <img
                src={H_hyundai}
                alt=""
                className={`width-1 ${
                  isMobile
                    ? "margin-bottom16"
                    : "margin-left24 padding-left-right16"
                }`}
              />
            </div>
            <div className="margin-bottom16">
              <a href="/privacy-policy" className="links">
                <span>Privacy Policy</span>
              </a>
              <span className="links">|</span>
              <a href="/terms-of-use" className="links">
                <span>Terms of Use</span>
              </a>
              <span className="links">|</span>
              <a href="/cookie-policy" className="links">
                <span>Cookie Policy</span>
              </a>
            </div>
          </div>
          <div>
            <a
              href="https://www.facebook.com/HyundaiIndia"
              target="_blank"
              className={`${isMobile ? "padding-right8" : "socials"}`}
              rel="noreferrer"
            >
              <img src={facebook} alt="" className="fb" />
            </a>
            <a
              href="https://twitter.com/HyundaiIndia"
              target="_blank"
              className="socials"
              rel="noreferrer"
            >
              <img src={X} alt="" className="x" />
            </a>
            <a
              href="https://www.instagram.com/p/C1jZcbQtfzS/"
              target="_blank"
              className="socials"
              rel="noreferrer"
            >
              <img src={insta} alt="" />
            </a>
            <a
              href="https://www.youtube.com/hyundaiindia"
              target="_blank"
              className="socials"
              rel="noreferrer"
            >
              <img src={yt} alt="" />
            </a>
          </div>
        </div>

        <div className="footer-desc">
          Product information, such as design, properties, and specifications,
          is for reference only and is subject to change without notice. The
          availability of specific product options may vary between countries
          and specific models.
          <br />
          <br />
          Copyright 2024 Hyundai Motor India. All Rights Reserved
        </div>
      </footer>
    </div>
  );
}

// Defining Proptypes
MyCollectionView.prototype = {
  myCollectionAction: PropTypes.func.isRequired,
};

// Access Redux State
const mapStateToProps = (state) => ({
  collectionList: state.myCollectionState.collectionList,
});

export default connect(mapStateToProps, { myCollectionAction })(
  MyCollectionView
);
