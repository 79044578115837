import React from "react";
import { useNavigate } from "react-router-dom";
import hyundai_logo from "../../Assets/hyundai.svg";
import hyundai_small from "../../Assets/hyundaiSmall.svg";
import { isMobile } from "../../Utils/utils";
import "./Navbar.css";
import arrow from "../../Assets/arrow.svg";

function Navbar({ handleCollection, visible }) {
  const navigate = useNavigate();
  const handleLogo = () => {
    navigate("/");
  };

  return (
    <div
      className={`navbar-container  justify-content-between ${
        visible ? "visible" : "hidden"
      }`}
    >
      <img
        src={`${isMobile ? hyundai_small : hyundai_logo}`}
        alt="Hyundai-Logo"
        className="hyundai-logo cursorPointer h-auto"
        onClick={handleLogo}
      />
      <div className="d-flex gap-4 product-link">
        <a
          href="https://www.hyundai.com/in/en/find-a-car/creta/highlights"
          rel="noopener noreferrer"
          target="_blank"
          className="product-link header__nav--main glb-ani__arrow-right"
        >
          <span className="text-decoration-none">Product Page</span>
          <img src={arrow} alt="" className="width-5" />
        </a>

        <div className="mycollection cursorPointer" onClick={handleCollection}>
          My Collection
        </div>
      </div>
    </div>
  );
}

export default Navbar;
