import React, { useState } from "react";
import { ReactComponent as Cross } from "../../Assets/cross.svg";
import download_btn from "../../Assets/download.svg";
import fullscreen_btn from "../../Assets/fullscreen.svg";
import FullScreenImage from "./Fullscreen";
import "./styles.css";
import { ReactComponent as Copy } from "../../Assets/copy.svg";
import { isMobile } from "../../Utils/utils";

const PreviewScreen = ({
  homeCardDetails,
  setHomeCardDetails,
  collectionCardDetails,
  setCollectionCardDetails,
  activeDetails,
}) => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleHover = () => {
    setHovered(!hovered);
  };

  const handleDownloadImage = () => {
    const activeDetailsObject =
      activeDetails === "home" ? homeCardDetails : collectionCardDetails;

    if (activeDetailsObject.length !== 0) {
      window.open(activeDetailsObject.downloadLink);
    }
  };

  const handlePreviewScreen = () => {
    if (activeDetails === "home") {
      setHomeCardDetails([]);
    } else if (activeDetails === "collection") {
      setCollectionCardDetails([]);
    }
  };

  const handleCloseFullScreen = () => {
    setShowFullScreen(false);
  };

  const handleOpenFullScreen = () => {
    setShowFullScreen(true);
  };

  const copyPrompt = () => {
    const promptText = homeCardDetails.prompt;
    console.log("prompt", promptText);

    navigator.clipboard
      .writeText(promptText)
      .then(() => {
        setHomeCardDetails([]);
      })
      .catch((error) => {
        console.error("Unable to copy text to clipboard:", error);
      });
  };

  return (
    <>
      <div className="previewscreen">
        <div className="previewscreen-container d-flex align-items-center flex-d-col">
          {/* //remove align-items and justify-content */}
          <div
            className="d-flex justify-content-end"
            style={{
              backgroundImage: `url(${
                activeDetails === "home"
                  ? homeCardDetails.image
                  : collectionCardDetails.image
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              width: isMobile ? "85%" : "80%",
              height: isMobile ? "50%" : "80%",
              marginTop: "5em",
            }}
          >
            <div className="d-flex flex-d-col justify-content-space-between imageControls">
              <img
                src={fullscreen_btn}
                alt=""
                className="cursorPointer"
                onClick={handleOpenFullScreen}
              />
              <img
                src={download_btn}
                alt=""
                className="cursorPointer"
                onClick={handleDownloadImage}
              />
            </div>
          </div>
          <div
            className={`bottom-0 ${
              isMobile ? "width-85" : "position-absolute width-80 "
            }`}
          >
            <p
              style={{
                fontSize: isMobile ? "16px" : "18px",
                lineHeight: "135%",
                marginTop: isMobile ? "1em" : "2em",
                paddingBottom: "0.5em",
                wordWrap: "break-word",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  fontWeight: "700",
                  paddingRight: "5px",
                }}
              >
                The all-new Creta, takes me to
              </span>
              {activeDetails === "home"
                ? homeCardDetails.prompt.split(",")
                : collectionCardDetails.prompt.split(",")}
              <button
                className="border-0 copyPrompt"
                onClick={copyPrompt}
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
              >
                <Copy
                  className={`${hovered ? "copyPromptHovered" : "copyPrompt"}`}
                />{" "}
                <span
                  className={`HyundaiSansHead-Medium font-weight500 ${
                    hovered ? "copyspanHovered" : "copyspan"
                  }`}
                >
                  Copy Prompt
                </span>
              </button>
            </p>
          </div>
          <div className="close-button" onClick={handlePreviewScreen}>
            <Cross className="close" />
          </div>
        </div>
      </div>
      {showFullScreen && (
        <FullScreenImage
          imageUrl={
            activeDetails === "home"
              ? homeCardDetails.image
              : collectionCardDetails.image
          }
          onCloseFullScreen={handleCloseFullScreen}
        />
      )}
    </>
  );
};

export default PreviewScreen;
