import { GET_COLLECTION_IMG } from "../Actions/Types";

// Defining Initial State
const initialState = {
  collectionList: [],
};

// Defining Reducer Function
export const myCollectionState = (state = initialState, action) => {
  // Destructuring Action
  const { type, payload } = action;

  switch (type) {
    // Add Collection Case
    case GET_COLLECTION_IMG: {
      return {
        ...state,
        collectionList: payload,
      };
    }
    // Default Case
    default: {
      return state;
    }
  }
};
