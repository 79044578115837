// Package Imports
import React from "react";
import { Routes, Route } from "react-router-dom";

// View Imports
import HomeView from "../Views/Home/HomeView";
import MyCollectionView from "../Views/Home/MyCollectionView";
import Privacy from "../Views/Home/Privacy";
import Terms from "../Views/Home/Terms";
import Cookie from "../Views/Home/Cookie";

const HomeRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeView />} />
      <Route path="/my-collection" element={<MyCollectionView />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/terms-of-use" element={<Terms />} />
      <Route path="/cookie-policy" element={<Cookie />} />
    </Routes>
  );
};

export default HomeRoute;
