// Package Imports
import { combineReducers } from "redux";

// Reducer Imports
import { deviceInfoState } from "./deviceInfoReducer";
import { myCollectionState } from "./myCollectionReducer";
import { getGridState } from "./getGridReducer";
import { genAiImageState } from "./genAiImageReducer";

export default combineReducers({
  deviceInfoState,
  myCollectionState,
  getGridState,
  genAiImageState,
});
