import React, { useState } from "react";
import { isMobile } from "../../Utils/utils";
import tryAgain from "../../Assets/tryagain.svg";
import Modal2 from "./Modal2";

function ErrorModal() {
  const [showModal2, setShowModal2] = useState(false);

  const handleTryAgain = () => {
    setShowModal2(true);
  };

  return (
    <>
      {!showModal2 ? (
        <div className="errorModal flex-d-col flex-center white-bg-color font  padding-top">
          <p
            className={`HyundaiSansHead-Medium color-blue textCenter textMargin  ${
              isMobile ? "font-size18 width-70 padding-top25" : "font-size32"
            }`}
          >
            Sorry—something went wrong. Please try again. Avoid specific brands
            or animals/humans in your prompt and check for any missing letters.
          </p>
          <button
            className="create-button margin-top30 margin-bottom24"
            onClick={handleTryAgain}
          >
            <img src={tryAgain} alt="" className="padding-right16 " />
            Try Again
          </button>
        </div>
      ) : null}
      {showModal2 && <Modal2 />}
    </>
  );
}

export default ErrorModal;
