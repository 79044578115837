import { genAiImageService } from "../../Services/genAiImageService";
import { GEN_AI_IMAGE } from "./Types";

//#region genAiImageAction
export const genAiImageAction = (data) => async (dispatch) => {
  try {
    // Calling Service
    console.log("data", data);
    const result = await genAiImageService(data);

    // Dispatching To Reducer
    dispatch({
      type: GEN_AI_IMAGE,
      payload: result,
    });

    return result;
  } catch (err) {
    // Errors
    let errors = err.response.data.errors;

    if (errors !== undefined) {
      for (let x of errors) {
        // Errors Alert
        dispatch((x.msg, "error", 3000));
      }
    } else {
      return Promise.reject(err);
    }
  }
};
// #endregion
