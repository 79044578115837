import React from "react";
import refresh from "../../Assets/refresh.svg";
import "./styles.css";

function PromptCard({
  promptList,
  onRefresh,
  handlePromptClick,
  promptCardRef,
}) {
  return (
    <div
      className="prompt-card width-max-content d-flex flex-d-col position-absolute"
      ref={promptCardRef}
    >
      {/* <p className="prompts-type textColorWhite HyundaiSansHead-Medium">
        {promptType}
      </p> */}
      <div className="d-flex flex-d-col textColorWhite prompts">
        {promptList.slice(0, 5).map((item, index) => (
          <label
            onClick={() => handlePromptClick(item)}
            key={index}
            className="cursorPointer prompts textCenter"
          >
            {item}
          </label>
        ))}
      </div>
      <div className="textCenter padding-top8 cursorPointer">
        <img
          src={refresh}
          alt=""
          className="width-fit-content"
          onClick={onRefresh}
        />
      </div>
    </div>
  );
}

export default PromptCard;
