import React, { useState, useEffect } from "react";
import "./styles.css";
import { isMobile } from "../../Utils/utils.js";

function Modal1({ Modal1Button }) {
  const [text, setText] = useState("");
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const originalText = [
    "A valley surrounded by striking mountains at sunset",
    "fields of giant mushrooms in luminous sunshine",
    "pink-blossom fantasy forests in vibrant colors",
    "jagged mountains under northern lights and starry skies",
    "cotton-candy worlds with trees made of sweets",
  ];

  useEffect(() => {
    if (currentIndex < originalText.length) {
      let currentText = originalText[currentIndex];
      let charIndex = 0;

      const interval = setInterval(() => {
        if (charIndex <= currentText.length) {
          setText(currentText.substring(0, charIndex));
          setDisplayText(currentText.substring(0, charIndex));
          charIndex++;
        } else {
          clearInterval(interval);
          setTimeout(() => {
            setCurrentIndex(currentIndex + 1);
          }, 1500);
        }
      }, 40);

      return () => clearInterval(interval);
    }
  }, [currentIndex]);

  return (
    <>
      {isMobile ? (
        <div className="modal1">
          <button className="create-button width-100" onClick={Modal1Button}>
            Create Your Own
          </button>
        </div>
      ) : (
        <div
          className="modal1 white-bg-color cursorPointer"
          onClick={Modal1Button}
        >
          <div className="d-flex flex-d-col justify-content-center width-100 padding-bottom14">
            <label className="label">
              I want to take the new Hyundai CRETA to...
            </label>
            <div className="ticker-text width-100 height-100">
              {displayText}
            </div>
          </div>
          <button className="create-button width-40" onClick={Modal1Button}>
            Create Your Own
          </button>
        </div>
      )}
    </>
  );
}

export default Modal1;
