import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Layout/Navbar";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import { isMobile } from "../../Utils/utils";
import facebook from "../../Assets/facebook.svg";
import insta from "../../Assets/insta.svg";
import X from "../../Assets/x.svg";
import yt from "../../Assets/youtube.svg";
import H_hyundai from "../../Assets/HyundaiH.svg";

function Cookie() {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos <= 100);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const handleMyCollection = async () => {
    navigate("/my-collection");
  };

  return (
    <div className="container">
      <Navbar handleCollection={handleMyCollection} visible={visible} />
      <div className="termsContainer">
        <h1 className="terms-heading">Cookie Policy</h1>
        <p>
          This Cookies Policy ('Policy') describes the different types of
          cookies that may be used in connection with Hyundai Motor India
          Limited’s ('Hyundai,' 'we,' 'us,' or 'our')
          <a href="https://UltimateCreta.ai" className="terms-links">
            <span>https://UltimateCreta.ai</span>
          </a>
          Digital website at santafe-openformore[HM1].hyundai.com including
          through any of our website’s contact forms, and any features,
          promotions, offers, or services provided by this website (collectively
          the 'Site').
          <br />
          This Policy only applies to the Site and not to any other website
          owned or operated by Hyundai.
          <br />
          It is important that you check back often for updates to the Policy as
          we may change it from time to time to reflect changes to our use of
          cookies. Please check the date at the top of this page to see when
          this Policy was last revised. Any changes to this Policy will become
          effective when we make the revised Policy available on our Site.
        </p>
        <h4>1. What are cookies</h4>
        <p>
          A cookie is any kind of file or device that is downloaded to a user’s
          system for the purpose of storing data that may be updated or
          retrieved by the company responsible for its installation. The main
          purpose of cookies is to make it quicker for you to access the Site
          and to tailor the Site’s features to you, allowing information of
          interest or potentially of interest to be provided to you depending on
          your use and interests.
        </p>
        <p>
          We use cookies on this Site in order to understand how you use our
          Site and to improve your experience. Cookies are text files which are
          stored on your computer. After you have finished your session and
          closed your browser many of the text files are deleted ('session
          cookies'). So-called 'persistent cookies' are also stored and these
          allow us to recognize you during your next visit on our Site. You can
          prevent cookies being saved on your computer by changing your browser
          settings. This may result in some functions used by our Site being
          restricted.
        </p>
        <h4>2. Types of Cookies</h4>
        <h5>Depending on the company managing them:</h5>
        <li>
          Our own cookies: Cookies that are sent to your system from a system or
          domain managed by us.
        </li>
        <li>
          Third party cookies: Cookies that are sent to your system from a
          system or domain that is not managed by us but by another company
          processing the data obtained through the cookies on our behalf (e.g.
          our service providers).
        </li>

        <h5>Depending on the period of time that they remain active:</h5>
        <li>
          Session cookies: Cookies designed for gathering and storing data while
          you are using the Site. They are usually used to store information
          that is only intended for providing the service or feature requested
          by the user on one single occasion.
        </li>
        <li>
          Persistent cookies: In this type of cookie, the data continues to be
          stored on your system and may be accessed and processed during a
          specific period of time by the manager of the cookie, which may range
          between several minutes and several years.
        </li>

        <h5>Depending on their purpose:</h5>
        <li>
          Technical cookies: Cookies that allow the user to browse a website,
          platform or application, and use the different options or services
          offered, such as, for example, controlling data traffic and
          communication, identifying the session, accessing restricted areas,
          recalling the parts of an order, carrying out the process for purchase
          of an order, making a request to register for or participate in an
          event, using security elements during browsing, storing contents for
          the transmission of videos or sound or sharing contents through social
          networks.
        </li>
        <li>
          Analytical cookies: Cookies that enable the manager to monitor and
          analyze the behavior of the users of the websites to which they are
          linked. The information collected by means of this type of cookie is
          used to measure the activity of a website and analyze and improve the
          website’s functionality and design.
        </li>
        <li>
          Advertising cookies: Cookies that enable the most efficient management
          possible of the advertising space that the editor has included in a
          website, application or platform from which the requested service is
          provided on the basis of criteria such as the edited content or the
          frequency with which the advertisements are shown.
        </li>
        <br />
        <br />
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Cookie Type</th>
                <th>Cookie Name</th>
                <th>Usage & Description</th>
                <th>Expiration</th>
                <th>First Party / Third Party</th>
                <th>Cookie Provider Policy</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Necessary Cookie</td>
                <td>Cookie Consent</td>
                <td>
                  Determines whether the visitor has accepted the cookie consent
                  field.
                </td>
                <td>Session</td>
                <td>Third-Party(CookieBot)</td>
                <td>
                  <a
                    href="https://www.cookiebot.com/en/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.cookiebot.com/en/privacy-policy/
                  </a>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>Advertising Cookies</td>
                <td>bkdc</td>
                <td>
                  These cookies are typically set by websites to register
                  anonymized user data (IP address, geolocation, etc.) and
                  aggregate anonymous activity so that marketers can provide
                  more targeted online advertising.
                </td>
                <td>6 months</td>
                <td>Third-Party (Oracle Data Cloud (formerly BlueKai))</td>
                <td>
                  <a
                    href="https://www.oracle.com/legal/privacy/advertising-privacy-policy.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.oracle.com/legal/privacy/advertising-privacy-policy.html
                  </a>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>Advertising Cookie</td>
                <td>bkpa</td>
                <td>
                  Used to display user- relevant content and ads - The service
                  offers a set of advertising products, such as real-time
                  bidding from third- party advertisers.
                </td>
                <td>6 months</td>
                <td>Third-Party (Oracle Data Cloud (formerly BlueKai))</td>
                <td>
                  <a
                    href="https://www.oracle.com/legal/privacy/advertising-privacy-policy.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.oracle.com/legal/privacy/advertising-privacy-policy.html
                  </a>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>Neccessary Cookie</td>
                <td>bku</td>
                <td>
                  These cookies are typically served by bluekai.com and are used
                  for advertising purposes.
                </td>
                <td>6 months</td>
                <td>Third-Party (Oracle Data Cloud (formerly BlueKai))</td>
                <td>
                  <a
                    href="https://www.oracle.com/legal/privacy/advertising-privacy-policy.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.oracle.com/legal/privacy/advertising-privacy-policy.html
                  </a>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>Analytics Cookie</td>
                <td>_ga_H349076NKW</td>
                <td>Used to persist session state.</td>
                <td>1 year</td>
                <td>Third-Party (Google)</td>
                <td>
                  <a
                    href="https://policies.google.com/privacy?hl=en-US"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://policies.google.com/privacy?hl=en-US
                  </a>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>Analytics Cookie</td>
                <td>_ga</td>
                <td>
                  Registers a unique ID that is used to generate statistical
                  data on how the visitor uses the website.
                </td>
                <td>2 years</td>
                <td>Third-Party (Google)</td>
                <td>
                  <a
                    href="https://policies.google.com/privacy?hl=en-US"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://policies.google.com/privacy?hl=en-US
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h4>3. How We Use Cookies and Related Technologies</h4>
        <p>
          Our Site uses a combination of our own and third-party technical,
          analytical, advertising, and behavioral advertising cookies . The data
          processed by technical cookies is required for the purposes mentioned
          above in order to protect our legitimate interests, in order to
          provide you with our Site, to ensure system stability and efficiency,
          and to implement proper safeguards of the security of our Site. The
          data processed by analytical, advertising and/or behavioral
          advertising cookies are not strictly necessary for visiting our Site.
          If required by applicable law, we will only process data by
          analytical, advertising and/or behavioral advertising cookies if you
          gave us your prior consent.
        </p>
        <p>
          Our Site may also use beacons, clear GIFs/pixel tags, JavaScript,
          local storage, log files, and other mechanisms to automatically
          collect and record information about your browsing activities, and use
          of the Site. We may combine this data with other personal information
          we collect about you. This data, and the data we collect from cookies,
          is used to understand how our Site is used, track bugs and errors,
          provide and improve our Site, track sessions, prevent fraud, and
          protect our Site, as well as for targeted marketing and advertising,
          to personalize content, and for analytics purposes. We provide
          additional information about tracking technologies below. You can
          learn about how to register your preferences regarding certain uses of
          the technologies by reading the Managing Cookies and Tracking
          Preferences section below.
        </p>
        <p>
          Web beacons. Some of our Site’s web pages, email messages, and
          newsletters may contain electronic images known as web beacons, which
          are also called single-pixel GIFs. Web beacons collect information
          including a cookie number, time and date of a page view and a
          description of the page on which the web beacon resides. Web beacons
          are a technique we may use to enhance and personalize the Site and
          services we offer, to provide product information and advertisements
          that are more relevant to your interests, compile aggregated
          statistics about the usage of our Site and other services, improve and
          personalize our advertising, and to track the number of users who have
          opened and acted upon our commercial email messages.
        </p>
        <p>
          Log files. We collect log information to analyze trends, administer
          our Site, track users’ movement around our Site, and gather broad
          demographic information for aggregate use in improving the Site and
          other services.
        </p>
        <p>
          Do Not Track. Our Site does not support Do Not Track at this time. Do
          Not Track is a privacy preference that you can set in your web browser
          to indicate that you do not want certain information about your
          webpage visits collected across websites when you have not interacted
          with that service on the page. You may also specify a cookies
          preference that has a similar effect as a do-not-track request.
        </p>
        <p>
          Third-Party Analytics. We may use third-party analytics companies, for
          example Google Analytics and Adobe Analytics Cloud (see our Privacy
          Policy), to evaluate use of our Site. We use these tools to help us
          understand use of, and to improve, our Site and other services,
          performance, ad campaigns, and user experiences. These entities may
          use cookies and other tracking technologies, such as web beacons or
          local storage objects, to perform their services.
        </p>

        <h4>4. Managing Cookies and Tracking Preferences</h4>
        <p>
          If you would like to opt-out of third party collection of information
          on our Site via cookies and similar tracking technologies, you can
          manage the preferences within your web browser. You will need to
          manage these preferences on all of the browsers and devices you use to
          access our Site. If you clear cookies on your browser, you will need
          to reset your managed preferences.
        </p>
        <p>
          You can also set your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, some Site features or services
          may not function properly without cookies. You may disable and
          otherwise choose which cookies you want on this Site by setting the
          cookie preferences on your browser.
        </p>

        {/* <h4>5. How to Contact Us</h4> */}
        {/* <p>
          Please contact us at [Email ID] if you have any complaints or
          questions about this Cookies Policy or our information practices.
        </p> */}
      </div>
      <footer className="footer">
        <div
          className={`d-flex justify-content-between padding-bottom24 ${
            isMobile ? "flex-d-col padding-left12" : "flex-d-row"
          }`}
        >
          <div className={`d-flex ${isMobile ? "flex-d-col" : "flex-d-row"}`}>
            <div>
              <img
                src={H_hyundai}
                alt=""
                className={`width-1 ${
                  isMobile
                    ? "margin-bottom16"
                    : "margin-left24 padding-left-right16"
                }`}
              />
            </div>
            <div className="margin-bottom16">
              <a href="/privacy-policy" className="links">
                <span>Privacy Policy</span>
              </a>
              <span className="links">|</span>
              <a href="/terms-of-use" className="links">
                <span>Terms of Use</span>
              </a>
              <span className="links">|</span>
              <a href="/cookie-policy" className="links">
                <span>Cookie Policy</span>
              </a>
            </div>
          </div>
          <div>
            <a
              href="https://www.facebook.com/HyundaiIndia"
              target="_blank"
              className={`${isMobile ? "padding-right8" : "socials"}`}
              rel="noreferrer"
            >
              <img src={facebook} alt="" className="fb" />
            </a>
            <a
              href="https://twitter.com/HyundaiIndia"
              target="_blank"
              className="socials"
              rel="noreferrer"
            >
              <img src={X} alt="" className="x" />
            </a>
            <a
              href="https://www.instagram.com/p/C1jZcbQtfzS/"
              target="_blank"
              className="socials"
              rel="noreferrer"
            >
              <img src={insta} alt="" />
            </a>
            <a
              href="https://www.youtube.com/hyundaiindia"
              target="_blank"
              className="socials"
              rel="noreferrer"
            >
              <img src={yt} alt="" />
            </a>
          </div>
        </div>

        <div className="footer-desc">
          Product information, such as design, properties, and specifications,
          is for reference only and is subject to change without notice. The
          availability of specific product options may vary between countries
          and specific models.
          <br />
          <br />
          Copyright 2024 Hyundai Motor India. All Rights Reserved
        </div>
      </footer>
    </div>
  );
}

export default Cookie;
