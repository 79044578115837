import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import add from "../../Assets/add.svg";
import download from "../../Assets/download.svg";
import edit from "../../Assets/edit.svg";
import fullscreen from "../../Assets/fullscreen.svg";
import minus from "../../Assets/minus.svg";
import shuffle from "../../Assets/shuffle.svg";
import sparkle from "../../Assets/sparkle.svg";
import { genAiImageAction } from "../../Redux/Actions/genAiImageAction.js";
import { destinations, elements, mood, weather } from "../../Utils/constant.js";
import { isMobile } from "../../Utils/utils";
import { deviceInfoAction } from "../../Redux/Actions/deviceInfoAction.js";
import Button from "./Button";
import FullScreenImage from "./Fullscreen.jsx";
import LoadingModal from "./LoadingModal.jsx";
import PromptCard from "./PromptCard";
import "./styles.css";
import ErrorModal from "./ErrorModal.jsx";

function Modal2({
  handleModalToggle,
  getLatestImages,
  genAiImageAction,
  deviceInfoAction,
}) {
  const [selectedTab, setSelectedTab] = useState("Assistive");
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [hideModal2, setHideModal2] = useState(true);
  const [activeButton, setActiveButton] = useState(null);
  const [showPromptCard, setShowPromptCard] = useState(false);
  const [isActiveButton1, setIsActiveButton1] = useState(true);
  const [isActiveButton2, setIsActiveButton2] = useState(false);
  const [isActiveButton3, setIsActiveButton3] = useState(false);
  const [isActiveButton4, setIsActiveButton4] = useState(false);
  const [destination, setDestination] = useState("Choose Your Destination");
  const [moodLabel, setMoodLabel] = useState("Celestial Mood");
  const [weatherLabel, setWeatherLabel] = useState("Preferred Weather");
  const [elementLabel, setElementLabel] = useState("Surrounding Elements");
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [aiImage, setAiImage] = useState();
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [freeTextInput, setFreeTextInput] = useState("");
  const [downloadLink, setDownloadLink] = useState("");
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const handleOpenFullScreen = () => {
    setShowFullScreen(true);
  };
  const handleCloseFullScreen = () => {
    setShowFullScreen(false);
  };
  const handleInputChange = (e) => {
    setIsTermsAccepted(e.target.checked);
  };
  const handleTextareaChange = (event) => {
    setFreeTextInput(event.target.value);
  };

  const promptCardRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      promptCardRef.current &&
      !promptCardRef.current.contains(event.target)
    ) {
      setShowPromptCard(false);
    }
  };

  const isGenerateImageDisabled =
    (selectedTab === "Assistive" &&
      (destination === "Choose Your Destination" ||
        moodLabel === "Celestial Mood" ||
        weatherLabel === "Preferred Weather" ||
        elementLabel === "Surrounding Elements")) ||
    (selectedTab === "Free Text" && !freeTextInput);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    switch (tab) {
      case "Free Text":
        setDestination("Choose Your Destination");
        setMoodLabel("Celestial Mood");
        setWeatherLabel("Preferred Weather");
        setElementLabel("Surrounding Elements");
        setIsActiveButton1(true);
        setIsActiveButton2(false);
        setIsActiveButton3(false);
        setIsActiveButton4(false);
        break;
      case "Assistive":
        setFreeTextInput("");
        break;
      default:
        break;
    }
  };

  const handleShuffleAll = () => {
    const randomDestination =
      destinations[Math.floor(Math.random() * destinations.length)];
    const randomMood =
      shuffledMood[Math.floor(Math.random() * shuffledMood.length)];
    const randomWeather =
      shuffledWeather[Math.floor(Math.random() * shuffledWeather.length)];
    const randomElement =
      shuffledElements[Math.floor(Math.random() * shuffledElements.length)];

    setDestination(randomDestination);
    setMoodLabel(randomMood);
    setWeatherLabel(randomWeather);
    setElementLabel(randomElement);

    setIsActiveButton1(true);
    setIsActiveButton2(true);
    setIsActiveButton3(true);
    setIsActiveButton4(true);
  };

  const getRandomDestination = () => {
    const randomIndex = Math.floor(Math.random() * destinations.length);
    const randomDestination = destinations[randomIndex];
    setDestination(randomDestination);
    setIsActiveButton1(true);
  };

  const handleMoodLabel = (labelText) => {
    setMoodLabel(labelText);
    setShowPromptCard(false);
    setIsActiveButton2(true);
  };

  const handleWeatherLabel = (labelText) => {
    setWeatherLabel(labelText);
    setShowPromptCard(false);
    setIsActiveButton3(true);
  };

  const handleElementLabel = (labelText) => {
    setElementLabel(labelText);
    setShowPromptCard(false);
    setIsActiveButton4(true);
  };

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    setShowPromptCard(true);
  };

  const getBrowserInfo = (userAgent) => {
    const browsers = {
      Chrome: /Chrome/i.test(userAgent),
      Safari: /Safari/i.test(userAgent),
      Firefox: /Firefox/i.test(userAgent),
      Edge: /Edg/i.test(userAgent),
      IE: /Trident/i.test(userAgent),
    };

    return (
      Object.keys(browsers).find((browser) => browsers[browser]) || "Unknown"
    );
  };

  const getOperatingSystem = (userAgent) => {
    const operatingSystems = {
      Windows: /Windows/i.test(userAgent),
      MacOS: /Mac/i.test(userAgent),
      iOS: /iPhone|iPad|iPod/i.test(userAgent),
      Android: /Android/i.test(userAgent),
      Linux: /Linux/i.test(userAgent),
    };

    return (
      Object.keys(operatingSystems).find((os) => operatingSystems[os]) ||
      "Unknown"
    );
  };

  const getDeviceInfo = async () => {
    const userAgent = navigator.userAgent;
    const browser = getBrowserInfo(userAgent);
    const os = getOperatingSystem(userAgent);

    const payload = {
      mobile: userAgent,
      browser: browser,
      os: os,
    };

    try {
      const deviceInfoFromAction = await deviceInfoAction(payload);

      localStorage.setItem("userId", deviceInfoFromAction?.userId);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleGenerateImage = async (tab) => {
    const storedUserId = localStorage.getItem("userId");

    if (!storedUserId) {
      await getDeviceInfo();

      setHideModal2(false);
      setShowLoadingModal(true);
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }

    const userId = localStorage.getItem("userId");

    setHideModal2(false);

    if (tab === "Assistive") {
      try {
        const payload = {
          tab: tab,
          userId: userId,
          Choose_your_Destination: destination,
          Celestial_Mood: moodLabel,
          Preferred_Weather: weatherLabel,
          Surrounding_Elements: elementLabel,
        };
        setShowLoadingModal(true);

        const genAiImageAssistive = await genAiImageAction(payload);

        setShowLoadingModal(false);

        if (genAiImageAssistive?.result === "Success") {
          getLatestImages();
          setShowDownloadModal(true);
          setAiImage(genAiImageAssistive?.image_url);
          setDownloadLink(genAiImageAssistive?.downloadLink);
        }
      } catch (ex) {
        console.log("Exception at submitting image", ex);
        setShowLoadingModal(false);
        setHideModal2(true);
        alert("Something went wrong, please try again");
      }
    } else {
      try {
        const payload = {
          tab: tab,
          userId: userId,
          freeText: freeTextInput,
          prompts: [],
        };
        setShowLoadingModal(true);

        const genAiImageFreeText = await genAiImageAction(payload);

        setShowLoadingModal(false);

        if (genAiImageFreeText?.result === "Success") {
          getLatestImages();
          setShowDownloadModal(true);
          setAiImage(genAiImageFreeText?.image_url);
          setDownloadLink(genAiImageFreeText?.downloadLink);
        } else if (
          genAiImageFreeText?.error === "Prompt contains blocked word(s)"
        ) {
          setHideModal2(false);
          setShowErrorModal(true);
        }
      } catch (ex) {
        console.log("Exception at submitting image", ex);
        setShowLoadingModal(false);
        setHideModal2(true);
        alert("Something went wrong, please try again");
      }
    }
  };

  const handleMinimize = () => {
    setShowDownloadModal(false);
    setHideModal2(true);
    setDestination("Choose Your Destination");
    setMoodLabel("Celestial Mood");
    setWeatherLabel("Preferred Weather");
    setElementLabel("Surrounding Elements");
    setIsActiveButton1(true);
    setIsActiveButton2(false);
    setIsActiveButton3(false);
    setIsActiveButton4(false);
    setIsTermsAccepted(false);
    setFreeTextInput("");
  };

  const handleCreateNew = () => {
    setShowDownloadModal(false);
    setHideModal2(true);
    setDestination("Choose Your Destination");
    setMoodLabel("Celestial Mood");
    setWeatherLabel("Preferred Weather");
    setElementLabel("Surrounding Elements");
    setIsActiveButton1(true);
    setIsActiveButton2(false);
    setIsActiveButton3(false);
    setIsActiveButton4(false);
    setIsTermsAccepted(false);
    setFreeTextInput("");
  };

  const shuffleArray = (promptList) => {
    const shuffledArray = promptList.slice().sort(() => Math.random() - 0.5);
    return shuffledArray;
  };
  const [shuffledWeather, setShuffledWeather] = useState(shuffleArray(weather));
  const [shuffledElements, setShuffledElements] = useState(
    shuffleArray(elements)
  );
  const [shuffledMood, setShuffledMood] = useState(shuffleArray(mood));

  const handleRefreshPromptList = (listType) => {
    switch (listType) {
      case "weather":
        const newShuffledWeather = shuffleArray(weather);
        setShuffledWeather(newShuffledWeather);
        break;
      case "elements":
        const newShuffledElements = shuffleArray(elements);
        setShuffledElements(newShuffledElements);
        break;
      case "mood":
        const newShuffledMood = shuffleArray(mood);
        setShuffledMood(newShuffledMood);
        break;
      default:
        break;
    }
  };

  const handleDownloadImage = () => {
    if (downloadLink) {
      const link = document.createElement("a");
      link.href = downloadLink;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      {hideModal2 && (
        <div className="modal2 flex-d-col white-bg-color">
          <div className="d-flex">
            <div className="width-100 flex-center margin-bottom24">
              <p
                className={`topbar-heads ${
                  selectedTab === "Assistive" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Assistive")}
              >
                Assistive
              </p>
              <p
                className={`topbar-heads ${
                  selectedTab === "Free Text" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Free Text")}
              >
                Free Text
              </p>
            </div>
            <div
              className="height-fit-content cursorPointer positionAbsolute"
              onClick={handleModalToggle}
            >
              <img src={minus} alt="" />
            </div>
          </div>
          {selectedTab === "Assistive" ? (
            <>
              <div
                className={`d-flex flex-d-col ${
                  isMobile ? "align-items-center" : "flex-wrap"
                }`}
              >
                <label className="label">
                  I want to take the new Hyundai CRETA to...
                </label>
                <div
                  className={`d-flex margin-bottom18 ${
                    isMobile
                      ? "flex-d-col align-items-center"
                      : "flex-d-row flex-wrap"
                  }`}
                >
                  <div className="flex-center margin-right12">
                    <Button
                      buttonHeading={destination}
                      icon={shuffle}
                      isActive={isActiveButton1}
                      onClick={getRandomDestination}
                    />
                  </div>
                  <div className="flex-center margin-right12">
                    <Button
                      buttonHeading={moodLabel}
                      icon={add}
                      isActive={isActiveButton2}
                      onClick={() => handleButtonClick(2)}
                    />
                    {activeButton === 2 && showPromptCard && (
                      <PromptCard
                        promptList={shuffledMood}
                        promptCardRef={promptCardRef}
                        handlePromptClick={handleMoodLabel}
                        onRefresh={() => handleRefreshPromptList("mood")}
                      />
                    )}
                  </div>
                  <div className="flex-center margin-right12">
                    <Button
                      buttonHeading={weatherLabel}
                      icon={add}
                      isActive={isActiveButton3}
                      onClick={() => handleButtonClick(3)}
                    />
                    {activeButton === 3 && showPromptCard && (
                      <PromptCard
                        promptList={shuffledWeather}
                        promptCardRef={promptCardRef}
                        handlePromptClick={handleWeatherLabel}
                        onRefresh={() => handleRefreshPromptList("weather")}
                      />
                    )}
                  </div>
                  <div className="flex-center margin-right12">
                    <Button
                      buttonHeading={elementLabel}
                      icon={add}
                      isActive={isActiveButton4}
                      onClick={() => handleButtonClick(4)}
                    />
                    {activeButton === 4 && showPromptCard && (
                      <PromptCard
                        promptList={shuffledElements}
                        promptCardRef={promptCardRef}
                        handlePromptClick={handleElementLabel}
                        onRefresh={() => handleRefreshPromptList("elements")}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="d-flex flex-d-col ">
              <label className="label">
                I want to take the new Hyundai CRETA to...
              </label>
              <textarea
                type="text"
                placeholder="…type anything and unleash your creativity."
                className="input"
                value={freeTextInput}
                onChange={handleTextareaChange}
              />
            </div>
          )}
          <div
            className={`d-flex width-100 ${
              (isMobile
                ? "flex-d-col align-items-center"
                : "flex-d-row align-items-center",
              selectedTab === "Assistive"
                ? "justify-content-space-between"
                : "justify-content-end")
            }`}
          >
            <p
              className={`flex-center cursorPointer width-fit-content color-blue ${
                selectedTab === "Assistive" ? "color-blue" : "hide"
              }`}
              onClick={() => handleShuffleAll()}
            >
              <img
                src={shuffle}
                alt=""
                className={`padding-right9_5 ${
                  selectedTab === "Assistive" ? "" : "hide"
                } `}
              />{" "}
              Shuffle All
            </p>
            <div
              className={`d-flex align-items-flex-end ${
                isMobile
                  ? "width-100 align-items-center flex-d-col"
                  : "align-items-center"
              }`}
            >
              <div
                className={`d-flex flex-d-row ${isMobile ? "mb-2" : "mr-3"}`}
              >
                <input
                  type="checkbox"
                  name="Terms"
                  id="termsCheckbox"
                  className="cursorPointer"
                  checked={isTermsAccepted}
                  onChange={handleInputChange}
                />

                <label className="terms padding-left8 padding-right19 cursorPointer">
                  Accept
                  <a
                    href="https://UltimateCreta.ai/terms-of-use"
                    className="termlinks"
                  >
                    <span>Terms & Conditions</span>
                  </a>
                </label>
              </div>
              <button
                className={`create-button ${isMobile ? "width-100" : ""} ${
                  isGenerateImageDisabled || !isTermsAccepted
                    ? "disabled-button"
                    : ""
                }`}
                onClick={() => handleGenerateImage(selectedTab)}
                disabled={isGenerateImageDisabled || !isTermsAccepted}
              >
                <img src={sparkle} alt="" className="padding-right12" />
                Generate Image
              </button>
            </div>
          </div>
        </div>
      )}

      {showLoadingModal && <LoadingModal />}
      {showErrorModal && <ErrorModal />}

      {showDownloadModal && (
        <div className="downloadModal flex-center flex-d-col padding16 yellow-bg-color">
          <div
            className="height-fit-content cursorPointer positionAbsolute"
            onClick={handleMinimize}
          >
            <img src={minus} alt="" />
          </div>
          <div
            className="d-flex flex-d-row justify-content-end"
            style={{
              backgroundImage: `url(${aiImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              width: "100%",
              height: "100%",
            }}
          >
            <div className="d-flex flex-d-col justify-content-space-between imageControls">
              <img
                src={fullscreen}
                alt=""
                className="cursorPointer"
                onClick={handleOpenFullScreen}
              />
              <img
                src={download}
                alt=""
                className="cursorPointer"
                onClick={handleDownloadImage}
              />
            </div>
          </div>
          {selectedTab === "Assistive" ? (
            <div
              className={`d-flex width-100 padding-top16 ${
                isMobile ? "flex-d-col" : "flex-d-row"
              }`}
            >
              <div className="d-flex flex-d-col text-align-left align-items-left width-100">
                <label className="label color-blue ">
                  I want to take the new Hyundai CRETA to...
                </label>
                <label className="finalPrompts">
                  {destination} {moodLabel} {weatherLabel} {elementLabel}
                </label>
                <div
                  className={`d-flex flex-d-row cursorPointer ${
                    isMobile ? "align-items-center" : "align-items-end"
                  }`}
                >
                  <img
                    src={edit}
                    alt=""
                    className={`cursorPointer edit ${
                      isMobile
                        ? " padding-top12 padding-bottom24 margin-right12"
                        : " padding-top12 margin-right12"
                    }`}
                  />
                  <span
                    className="editPrompt cursorPointer"
                    onClick={handleMinimize}
                  >
                    Edit Prompt
                  </span>
                </div>
              </div>
              <button
                className={`cursorPointer create-button ${
                  isMobile ? "width-100" : ""
                }`}
                onClick={handleCreateNew}
              >
                Create New
              </button>
            </div>
          ) : (
            <div
              className={`d-flex width-100 padding-top16 ${
                isMobile ? "flex-d-col" : "flex-d-row"
              }`}
            >
              <div className="d-flex flex-d-col text-align-left align-items-left width-100">
                <label className="label color-blue">
                  I want to take the new Hyundai CRETA to...
                </label>
                <label className="finalPrompts">{freeTextInput}</label>
                <div
                  className={`d-flex flex-d-row cursorPointer ${
                    isMobile ? "align-items-center" : "align-items-end"
                  }`}
                >
                  <img
                    src={edit}
                    alt=""
                    className={`cursorPointer edit ${
                      isMobile
                        ? "padding-top12 padding-bottom24 margin-right12"
                        : " padding-top12 margin-right12"
                    }`}
                    onClick={handleMinimize}
                  />
                  <span
                    className="editPrompt cursorPointer"
                    onClick={handleMinimize}
                  >
                    Edit Prompt
                  </span>
                </div>
              </div>
              <button
                className={`cursorPointer create-button ${
                  isMobile ? "width-100" : ""
                }`}
                onClick={handleCreateNew}
              >
                Create New
              </button>
            </div>
          )}
        </div>
      )}
      {showFullScreen && (
        <FullScreenImage
          imageUrl={aiImage}
          onCloseFullScreen={handleCloseFullScreen}
        />
      )}
    </>
  );
}

// Defining Proptypes
Modal2.prototype = {
  genAiImageAction: PropTypes.func.isRequired,
  deviceInfoAction: PropTypes.func.isRequired,
};

export default connect(null, { deviceInfoAction, genAiImageAction })(Modal2);
