import axios from "axios";
import { BASE_API } from "../GlobalVariable";

// #region myCollectionService
export const myCollectionService = async (userId) => {
  try {
    // Url
    const url = `${BASE_API}/user/get-user-collection/${userId}`;

    // Config
    const config = {
      headers: {
        "Content-type": "application/json",
        // Authorization: `Bearer token`,
      },
    };
    const result = await axios.get(url, config);

    return result?.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
// #endregion
