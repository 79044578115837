import { GET_USER_ID } from "../Actions/Types";

// Defining Initial State
const initialState = {
  deviceInfo: null,
};

// Defining Reducer Function
export const deviceInfoState = (state = initialState, action) => {
  // Destructuring Action
  const { type, payload } = action;
  // console.log("in reducer", payload?.userId);

  switch (type) {
    // Add Get User ID Case
    case GET_USER_ID: {
      return {
        ...state,
        deviceInfo: payload,
      };
    }

    // Default Case
    default: {
      return state;
    }
  }
};
