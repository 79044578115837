import axios from "axios";
import { BASE_API } from "../GlobalVariable";

// #region getGridImages
export const getGridImageService = async () => {
  try {
    // Url
    const url = `${BASE_API}/user/get-images`;

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer token`,
      },
    };
    const result = await axios.get(url, config);

    return result?.data;
  } catch (err) {
    return Promise.reject(err);
  }
};
// #endregion
